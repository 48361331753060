import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { graphql } from "gatsby";
import { Button, notification } from "antd";

import Pages from "../../../components/Pages";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import seoData from "../../../components/SEO/data";
import { ContentBlock } from "../../../components/Blocks";
import { TextBlock } from "../../../components/Blocks";
import { ContentList } from "../../../components/Lists";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../../helpers";
import { Share } from "../../../widgets";
import { showMore } from "../../../constants";
import { useThemeContext } from "../../../components/Layout/ThemeContext";

import { MEDIA_ARTICLES, MEDIA_ARTICLES_AGGREGATE } from "../../../queries/queries.graphql";

export const query = graphql`
	query MediaArticlesPage($slug: String!)  {
		hasura {
			...MediaArticles
		}
	}
`;

export default function MediaArticles({ data, pageContext }) {
	const title = "Статьи";
	const limit = 4;
	const entity = get(seoData, "media_article__page", {});
	const initialArticles = get(data, "hasura.cities[0].cities_media_articles", []);
	const { slug, title_full } = pageContext;
	const url = `${slug}/articles`;
	const breadcrumbsContext = {
		item: {
			slug,
			title_full,
		},
	};

	const { theme } = useThemeContext();
	const [offset, setOffset] = useState(null);

	const {
		loading: aggregateLoading,
		error: aggregateError,
		data: mediaArticlesAggregate,
	} = useQuery(MEDIA_ARTICLES_AGGREGATE, {
		variables: {
			slug,
		},
	});

	const count = get(mediaArticlesAggregate, "cities[0].cities_media_articles_aggregate.aggregate.count", 0);

	const makeData = useCallback((data = []) => data.map(({ title_short, title_full, slug, main_image_preview, main_image }) => {
		return {
			slug: makeUrl.mediaArticle({ slug }),
			image: main_image_preview && get(main_image_preview, "src", "")	|| get(main_image, "src", ""),
			title: title_short || title_full,
		};
	}), [makeUrl]);

	const [mediaArticles, setMediaArticles] = useState(makeData(initialArticles.map(item => item.media_article)));

	const [loadMediaArticles, {
		called,
		loading,
		error,
		data: mediaArticlesData,
	}] = useLazyQuery(
		MEDIA_ARTICLES,
		{
			variables: {
				slug,
				limit,
			},
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setMediaArticles(mediaArticles => {
				const newStateBlank = [...mediaArticles, ...makeData(get(mediaArticlesData, "cities[0].cities_media_articles", []).map(item => item.media_article))];

				setOffset(newStateBlank.length >= count ? null : offset + limit);

				return newStateBlank;
			});
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading, mediaArticlesData, called]);

	useEffect(() => {
		if (!aggregateError && initialArticles.length < count) {
			setOffset(limit);
		}

		if (aggregateError) {
			console.error("aggregate Error on ContentNews");
			notification.error({
				message: "Ошибка загрузки данных",
			});
		}
	}, [count, aggregateError]);

	const onLoadMore = useCallback(() => loadMediaArticles({
		variables: {
			offset,
		},
	})
	, [offset]);

	return (
		<Pages entity={entity} url={url}>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs currentLabel={title} pageContext={breadcrumbsContext} />
				</div>
			</ContentBlock>
			<ContentBlock>
				<div className={"container"}>
					<TextBlock title={title}>
						<ContentList
							items={mediaArticles}
							className={"col-12 col-md-3"}
							style={{
								width: "100%",
								height: "400px",
								objectFit: "cover",
							}}/>
					</TextBlock>
					<div className="row justify-content-center mb-5">
						<Button
							type={"primary"}
							disabled={loading || !offset || aggregateLoading}
							onClick={onLoadMore}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
							{...{ loading }}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={title} />
			</div>
		</Pages>
	);
}

MediaArticles.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

MediaArticles.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
		title_full: "",
	},
};
